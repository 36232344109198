import '../styles/globals.css';
import '../styles/expander.css';
import '../styles/modal.css';

// import 'nextra-theme-docs/style.css';
import 'antd/dist/antd.css';

import '@phpcreation/frontend-components-react/dist/build.css';
import '@phpcreation/frontend-components-crud-react/dist/build.css';

import type { AppProps } from 'next/app';
import React from 'react';
// Change if you are using crud library or not
import { AppContextProvider } from '@phpcreation/frontend-components-crud-react';
import { setApiEndpoints } from '../utils/constants/constants'

import en from '../utils/locales/en/common.json';
import fr from '../utils/locales/fr/common.json';

const messages = {
  en,
  fr,
};

const API_ENDPOINTS = {
  crud: process.env.NEXT_PUBLIC_CRUD_ENDPOINT ?? '',
  auth: process.env.NEXT_PUBLIC_AUTH_ENDPOINT ?? '',
  status: process.env.NEXT_PUBLIC_STATUS_ENDPOINT ?? '',
  documentation: process.env.NEXT_PUBLIC_DOC_ENDPOINT ?? ''
}

setApiEndpoints(API_ENDPOINTS);

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AppContextProvider endpoints={API_ENDPOINTS} extraMessages={messages}>
      <Component {...pageProps} />
    </AppContextProvider>
  );
}

export default MyApp;
