import { Endpoints } from "@phpcreation/frontend-components-crud-react";

//API
export const API_ENDPOINTS: Endpoints = {
  crud: process.env.NEXT_PUBLIC_CRUD_ENDPOINT ?? '',
  auth: process.env.NEXT_PUBLIC_AUTH_ENDPOINT ?? '',
  status: process.env.NEXT_PUBLIC_STATUS_ENDPOINT ?? '',
  documentation: process.env.NEXT_PUBLIC_DOC_ENDPOINT ?? ''
}

export const setApiEndpoints = (endpoints: Endpoints) => {
  API_ENDPOINTS.crud = endpoints.crud;
  API_ENDPOINTS.auth = endpoints.auth;
  API_ENDPOINTS.status = endpoints.status;
  API_ENDPOINTS.documentation = endpoints.documentation;
};
